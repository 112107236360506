import { useTranslation } from 'react-i18next';
import { Alert } from '@mui/material';

const PaymentNoticeInfo = (props: IProps) => {
  const { t } = useTranslation();

  return (
    <Alert severity="info" icon={false} sx={{ fontSize: '12px' }}>
      {t('payment_notice', { company_name: props.companyName })}
    </Alert>
  );
};

export default PaymentNoticeInfo;

interface IProps {
  companyName: string;
}
