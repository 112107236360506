import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, CircularProgress, Stack, TextField } from '@mui/material';

import { translateBackendErrors } from '../../../../../../helpers/paymentHelper';
import { EmailRegEx } from '../../../../../../helpers/regularExpressions';
import useDebounce from '../../../../../../helpers/useDebounce';
import {
  authorizePayment,
  SupportedBackendErrors,
} from '../../../../../../services/authorizePaymentService';
import {
  checkPaymentAuthorizeInformationService,
  ICheckPaymentAuthorizeInformationServiceResponse,
} from '../../../../../../services/checkPaymentAuthorizeInformationService';
import {
  BankAccountType,
  ILinks,
  LinkRelation,
  PaymentStatus,
  PaymentType,
} from '../../../../../../services/commonTypes';
import IbanField from '../../../../../shared/IbanField';
import SelectField from '../../../../../shared/SelectField';
import { IPaymentMethodPropsBase } from '../PaymentMethodsTypes';
import PaymentNoticeInfo from '../shared/PaymentNoticeInfo';

import '../PaymentMethods.scss';

const Sdd = (props: IProps) => {
  const { t } = useTranslation();

  const [errors, setErrors] = useState<IErrors>({});
  const [errorMessage, setErrorMessage] = useState<string | null | undefined>(props.errorMessage);
  const [isLoading, setIsLoading] = useState(false);
  const [askAccountType, setAskAccountType] = useState(false);

  const ibanRef = useRef<HTMLInputElement>(null);
  const bankTypeRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);

  const isFormValid = (): boolean => {
    const validationErrors: IErrors = {};

    if (!ibanRef.current?.value) {
      validationErrors.iban = t('validation_errors.required');
    }

    if (!bankTypeRef.current?.value) {
      validationErrors.bankAccountType = t('validation_errors.required');
    }

    if (!emailRef.current?.value) {
      validationErrors.email = t('validation_errors.required');
    } else if (!EmailRegEx.test(emailRef.current.value)) {
      validationErrors.email = t('validation_errors.invalid_email');
    }

    setErrors(validationErrors);

    return Object.keys(validationErrors).length === 0;
  };

  const handleSubmit = async (): Promise<void> => {
    const isValid = isFormValid();

    if (!isValid) {
      return;
    }

    setErrorMessage(null);
    setIsLoading(true);

    try {
      const authorizePaymentResult = await authorizePayment(
        props.authorizeLink,
        props.resourceToken,
        {
          bankAccountType:
            bankTypeRef.current?.value === 'business'
              ? BankAccountType.Business
              : BankAccountType.Consumer,
          email: emailRef.current?.value as string,
          iban: ibanRef.current?.value as string,
          type: PaymentType.Sdd,
        },
      );

      if ('errors' in authorizePaymentResult) {
        const authorizeErrors: IErrors = {};
        let authorizationErrorMessage = '';

        if (authorizePaymentResult.errors[SupportedBackendErrors.PaymentMethod]) {
          authorizationErrorMessage = translateBackendErrors(
            authorizePaymentResult.errors[SupportedBackendErrors.PaymentMethod],
            t,
          );
        }
        if (authorizePaymentResult.errors[SupportedBackendErrors.Iban]) {
          const ibanErrorMessage = translateBackendErrors(
            authorizePaymentResult.errors[SupportedBackendErrors.Iban],
            t,
          );
          authorizeErrors.iban = ibanErrorMessage;
        }
        if (authorizePaymentResult.errors[SupportedBackendErrors.Email]) {
          const emailErrorMessage = translateBackendErrors(
            authorizePaymentResult.errors[SupportedBackendErrors.Email],
            t,
          );
          authorizeErrors.email = emailErrorMessage;
        }

        if (!authorizeErrors && !authorizationErrorMessage) {
          throw new Error(
            `Unknown error when validating the data: ${authorizePaymentResult.errors}`,
          );
        }

        setErrors(authorizeErrors);
        setErrorMessage(authorizationErrorMessage);
        setIsLoading(false);
      } else {
        if (
          authorizePaymentResult.workflow.next === LinkRelation.Mandate &&
          authorizePaymentResult.links[authorizePaymentResult.workflow.next]
        ) {
          props.onPaymentStateUpdated(
            authorizePaymentResult.status,
            authorizePaymentResult.workflow.next,
            authorizePaymentResult.links,
          );
        } else if (
          authorizePaymentResult.workflow.next &&
          authorizePaymentResult.links[authorizePaymentResult.workflow.next]
        ) {
          const redirectionLink =
            authorizePaymentResult.links[authorizePaymentResult.workflow.next];
          window.location.href = redirectionLink.href;
        } else {
          throw new Error('Inconsistency at the payment workflow and links.');
        }
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      props.onUnhandledError(t('errors.payment_generic'));
    }
  };

  const handleIbanChange = async (iban: string): Promise<void> => {
    const result = await checkPaymentAuthorizeInformationService(props.resourceToken, {
      iban,
      type: 'sepa_direct_debit',
    });

    if ('errors' in result) {
      setAskAccountType(false);
      return;
    }

    const accountType = (result as ICheckPaymentAuthorizeInformationServiceResponse).accountType;
    if (accountType === undefined) {
      setAskAccountType(true);
      return;
    }

    if (bankTypeRef.current) {
      bankTypeRef.current.value = accountType;
      setAskAccountType(false);
    }
  };

  const handleDebouncedIbanChange = useDebounce(handleIbanChange, 500);

  useEffect(() => {
    props.onLoadingChange(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <div className="sdd-payment-container">
      <Stack spacing={2} className="payment-fields">
        {errorMessage && (
          <Alert severity="error" sx={{ marginBottom: '15px' }}>
            {errorMessage}
          </Alert>
        )}

        <IbanField
          id="iban"
          ref={ibanRef}
          onChange={handleDebouncedIbanChange}
          label={t('form_labels.iban')}
          errorMessage={errors.iban}
          disabled={isLoading}
          autoFocus
          required
        />

        <TextField
          id="email"
          inputRef={emailRef}
          defaultValue={props.customerEmail}
          label={t('form_labels.email')}
          error={Boolean(errors.email)}
          helperText={errors.email}
          disabled={isLoading}
          autoComplete="off"
          required
        />

        <SelectField
          id="bankAccountType"
          name="bankAccountType"
          ref={bankTypeRef}
          label={t('form_labels.bank_account_type')}
          disabled={isLoading}
          style={{ visibility: askAccountType ? 'visible' : 'hidden' }}
          errorStyle={{ display: askAccountType ? 'inline' : 'none' }}
          items={[
            {
              description: t(`bank_account_types.${BankAccountType.Business}`),
              value: BankAccountType.Business,
            },
            {
              description: t(`bank_account_types.${BankAccountType.Consumer}`),
              value: BankAccountType.Consumer,
            },
          ]}
          errorMessage={errors.bankAccountType}
          required
        />

        <PaymentNoticeInfo companyName={props.companyName} />
      </Stack>

      <Button
        id="completePaymentBtn"
        data-id="complete-sdd-payment-btn"
        className="complete-payment-btn"
        variant="contained"
        color="primary"
        size="large"
        type="submit"
        onClick={handleSubmit}
        disabled={isLoading}
      >
        {isLoading ? (
          <span style={{ marginRight: 10 }}>{t('buttons.processing_payment')}</span>
        ) : (
          t('buttons.complete_payment')
        )}
        {isLoading && <CircularProgress size={17} color="inherit" />}
      </Button>
    </div>
  );
};

export default Sdd;

interface IProps extends IPaymentMethodPropsBase {
  customerEmail: string | undefined;
  onPaymentStateUpdated: (
    paymentStatus: PaymentStatus,
    workflowNext: string,
    links: ILinks,
  ) => void;
}

interface IErrors {
  iban?: string | null;
  email?: string | null;
  bankAccountType?: string | null;
}
