export interface TokenPayload {
  jti: string;
  name: string;
  exp: number;
}

export const decodeToken = (token: string) => {
  try {
    const parts = token.split('.');
    if (parts.length !== 3) {
      throw new Error('Invalid token structure');
    }

    // Decode the payload (second part of the token)
    const payloadBase64 = parts[1];

    const decodedPayload = atob(payloadBase64); // Base64 decode
    const parsedPayload: TokenPayload = JSON.parse(decodedPayload); // Parse JSON to the specified type

    return parsedPayload; // Return the decoded and parsed payload
  } catch (error) {
    console.error('Failed to decode token:', error);
    return undefined;
  }
};
