import Countdown, { CountdownRenderProps } from 'react-countdown';
import { useTranslation } from 'react-i18next';
import { Alert } from '@mui/material';

import './ExpirationCountDown.scss';

const ExpirationCountDown = (props: IProps) => {
  const { t } = useTranslation();

  const getExpirationMessage = (minutes: number, seconds: number) =>
    minutes === 0
      ? t('expiration_countdown_message_last_min', { seconds })
      : t('expiration_countdown_message', { minutes, seconds });

  const renderer = ({ minutes, seconds, completed }: CountdownRenderProps) => (
    <div
      className={
        'expiration-countdown ' + (completed ? 'expired ' : '') + (minutes < 5 ? 'highlight' : '')
      }
    >
      {completed ? (
        <Alert severity="error" icon={false} sx={{ fontSize: '12px' }}>
          {t('errors.session_expired')}
        </Alert>
      ) : (
        <>{getExpirationMessage(minutes, seconds)}</>
      )}
    </div>
  );

  return (
    <Countdown date={props.expirationDate} onComplete={props.onComplete} renderer={renderer} />
  );
};

export default ExpirationCountDown;

interface IProps {
  expirationDate: Date;
  onComplete?: () => void;
}
